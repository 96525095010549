<template lang="html" src="./pageUser.template.vue"></template>

<style lang="scss" src="./pageUser.scss"></style>

<script>
import { UserProfile, ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageUser.i18n');

export default {
  name: 'PageUser',
  components: { UserProfile },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      user: null,
      globalErrorMessage: null,
    };
  },
  created() {
    /**
     * GET USER
     */
    this.getUser();
  },
  methods: {
    /**
     * GET USER
     */
    getUser() {
      this.$apiInstance.getUser(this.$route.params.userUUID).then(
        (data) => {
          this.user = data;
        },
        (error) => {
          /**
           * ERROR GET USER
           */
          ApiErrorParser.parse(error);
        }
      );
    },
  },
};
</script>
